<template>
  <div v-if="quizIndex < maxQuizLength" class="container">
    <a href="https://jiin.love/">
      <img class="logo" src="/icon/logo-sm.png" alt="모두의지인" />
    </a>
    <h1 class="q-num">Q{{quizIndex + 1}}</h1>
    <div class="bar"></div>
    <h2 class="q-num">{{quizIndex + 1}}/{{maxQuizLength}}</h2>
    <transition-group name="slide" tag="div" class="div-slider" v-for="(q, i) in quizList" :key="i">
      <h1 v-if="quizIndex === i && quizTransition" :key="`h-${i}`" class="q-title" v-html="q.q"></h1>
      <button
        v-if="quizIndex === i && quizTransition"
        :key="`a1-${i}`"
        class="answer-btn"
        @click="confirmAnswer(q.a[0])"
        v-html="q.a[0].text"
      ></button>
      <button
        v-if="quizIndex === i && quizTransition"
        :key="`a2-${i}`"
        class="answer-btn"
        @click="confirmAnswer(q.a[1])"
        v-html="q.a[1].text"
      ></button>
    </transition-group>
  </div>
  <div v-else-if="!loading" class="container">
    <a href="https://jiin.love/">
      <img class="logo" src="/icon/logo-sm.png" alt="모두의지인" />
    </a>
    <!-- <div class="input-field">
      <div class="user-input">
        <label for="name">이름</label>
        <input type="text" v-model="name" id="name" />
      </div>
      <div class="user-input">
        <label for="name">연락처</label>
        <input type="phone" v-model="phone" id="phone" />
      </div>
      <div class="privacy-row">
        <Checkbox id="privacy" @checked="(e) => {privacy = e}" />
        <label for="privacy">개인정보 수집 및 이용에 동의합니다.</label>
      </div>
    </div> -->
    <img :src="`/result/intj.png`" alt="intj" />
    <Button @click="moveToResult">결과 보기</Button>
  </div>
  <div v-else class="container">
    <Loading />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/Loading";
// import Checkbox from "@/components/Checkbox";
import firebase from "firebase/app";
import "firebase/firebase-firestore";
export default {
  components: {
    // Checkbox,
    Loading
  },
  computed: mapState({
    quizTransition: state => state.quizTransition,
    quizIndex: state => state.quizIndex,
    quizList: state => state.quiz,
    maxQuizLength: state => state.quiz.length,
    score: state => state.score
  }),
  data() {
    return {
      name: null,
      phone: null,
      privacy: false,
      loading: false
    };
  },
  created() {
    this.$store.commit("RESET");
  },
  mounted() {
    var firebaseConfig = {
      apiKey: "AIzaSyB1qawgxYH5OjzCsC3_x7TtGnIa-5KpPp4",
      authDomain: "jiinlove-website.firebaseapp.com",
      projectId: "jiinlove-website",
      storageBucket: "jiinlove-website.appspot.com",
      messagingSenderId: "975416266147",
      appId: "1:975416266147:web:1719b0f32e669a994b1e16"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  },
  methods: {
    confirmAnswer({ target, score }) {
      this.$store.commit("SET_SCORE", {
        target,
        score
      });
      this.$store.commit("ADD_INDEX");
      setTimeout(() => {
        this.$store.commit("DONE_TRANSITION");
      }, 200);
    },
    moveToResult() {
      // if (!this.name) {
      //   alert("이름을 입력해주세요.");
      //   return false;
      // }
      // if (!this.phone) {
      //   alert("이름을 입력해주세요.");
      //   return false;
      // }
      // if (!this.privacy) {
      //   alert("개인정보 수집 및 이용을 동의해주세요.");
      //   return false;
      // }
      
      var db = firebase.firestore();
      db.collection("MarriageAbilityMbti_users").add({
        name: this.name,
        phone: this.phone !== null ? this.phone.replace("-", "") : this.phone,
        createdAt: new Date().getTime()
      });
      this.loading = true;
      setTimeout(() => {
        this.$router.push({
          name: "Result",
          params: {
            id: this.getResult()
          }
        });
      }, 3000);
    },
    getResult() {
      return (
        `${this.score.e > this.score.i ? "e" : "i"}` +
        `${this.score.n > this.score.s ? "n" : "s"}` +
        `${this.score.t > this.score.f ? "t" : "f"}` +
        `${this.score.j > this.score.p ? "j" : "p"}`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.privacy-row {
  display: flex;
  gap: 1em;
  align-items: center;
}
</style>